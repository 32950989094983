import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  ElementBase,
  getChildren,
  InstrumentElement,
  NoteElement,
  SubInstrumentElement,
  TuningElement,
  ElementBaseHelper,
} from '../../instruments/instrument_defs';

import {CHROMATIC, Dispatch} from '../../redux/redux';
import {RootState} from '@abstractions/redux_inst';
import {AnimButton} from './anim_button';
import {
  BottomNavigation,
  BottomNavigationAction,
  DialogTitle,
  Dialog,
  Typography,
} from '@material-ui/core';

import {MyChooser} from './my_chooser';

import {
  Cancel as CancelIcon,
  DoneOutline as DoneOutlineIcon,
} from '@material-ui/icons';
import {dbg} from '../../debug/debug';
import useSnackbarHelp from './use_snackbar_help';

import '../../../../App.css';

// ----------------------------------------------------------------------------
export function MyChooser_renderInstrument(item: string): JSX.Element {
  return (
    <AnimButton>
      <Typography variant="body1">{item}</Typography>
    </AnimButton>
  );
}

// ----------------------------------------------------------------------------
const ChromaticInst: InstrumentElement = {
  name: CHROMATIC,
  subInstrument: [],
};

export type ChooseInstrumentProps = {
  open: boolean;
  onClose: () => void;
};

// ----------------------------------------------------------------------------
export function ChooseInstrumentScreen(props: ChooseInstrumentProps) {
  const [instrument, setInstrument] = useState<InstrumentElement | null>(null);
  const [
    subInstrument,
    setSubInstrument,
  ] = useState<SubInstrumentElement | null>(null);
  const [tuning, setTuning] = useState<TuningElement | null>(null);

  const [subInstrumentList, setSubInstrumentList] = useState<ElementBase[]>([]);
  const [tuningList, setTuningList] = useState<ElementBase[]>([]);
  const [listOfNotesAtBottom, setListOfNotesAtBottom] = useState('');
  const [doReset, setDoReset] = useState(false);
  const [instrumentNames, setInstrumentNames] = useState<string[]>([]);
  const [subInstrumentNames, setSubInstrumentNames] = useState<string[]>([]);
  const [tuningNames, setTuningNames] = useState<string[]>([]);
  const [applyButtonEnabled, setApplyButtonEnabled] = useState(false);
  const [applyButtonHelp, setApplyButtonHelp] = useState('');

  const instrumentsMap = useSelector((state: RootState) => {
    return state.system.allInstrumentsMap;
  });
  const numInstruments = useSelector((state: RootState) => {
    return state.system.allInstrumentsMap.size;
  });

  const dispatch = useDispatch();

  const help = useSnackbarHelp();

  // Called when the list of instruments changes size.
  useEffect(() => {
    dbg.log(
      'Handling new instrument mapping from size ' +
        numInstruments +
        ' to size ' +
        instrumentsMap.size,
    );
    setInstrument(null);
    setSubInstrument(null);
    setTuning(null);

    setDoReset(false);
    setInstrumentNames([CHROMATIC, ...instrumentsMap.keys()]);
  }, [instrumentsMap, numInstruments, doReset]);

  // Called when an instrument is chosen.
  // Fill up the subInstrument list
  useEffect(() => {
    dbg.logObj('instrument = ', instrument);
    if (instrument) {
      const arr = getChildren(instrument);
      if (arr) {
        setSubInstrumentList(arr);
        setSubInstrument(null);
      }
    }
  }, [instrument]);

  useEffect(() => {
    setSubInstrumentNames(
      subInstrumentList.map((v: ElementBase) => {
        return v.name;
      }),
    );
  }, [subInstrumentList]);

  // Called when an instrument is chosen.
  useEffect(() => {
    dbg.logObj('subInstrument = ', subInstrument);
    if (subInstrument) {
      const arr = getChildren(subInstrument);
      if (arr) {
        setTuningList(arr);
        setTuning(null);
      }
    }
  }, [subInstrument]);

  useEffect(() => {
    setTuningNames(
      tuningList.map((v: ElementBase) => {
        return v.name;
      }),
    );
  }, [tuningList]);

  useEffect(() => {
    let str = '';
    if (tuning) {
      tuning.noteElement.forEach((note: NoteElement, idx: number) => {
        str = str + note.name;
        if (idx !== tuning.noteElement.length - 1) {
          str = str + ', ';
        }
      });
    }
    setListOfNotesAtBottom(str);
  }, [tuning]);

  useEffect(() => {
    let enabled: boolean = !!instrument && instrument.name === CHROMATIC;
    if (enabled) {
      setApplyButtonHelp('Use chromatic tuner');
    } else {
      if (tuning) {
        enabled = true;
        const e = new ElementBaseHelper(tuning);
        setApplyButtonHelp(
          'Use ' +
            e.getGrandParentName() +
            '/' +
            e.getParentName() +
            '/' +
            e.getName(),
        );
      }
    }
    setApplyButtonEnabled(enabled);
  }, [instrument, tuning]);

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id="simple-dialog-title">
        Choose Instrument Tuning
      </DialogTitle>
      <div className="Settings" style={{flexDirection: 'column', flex: 1}}>
        <div style={{flexDirection: 'column', padding: 20, width: '100%'}}>
          <MyChooser
            options={instrumentNames}
            label={'Choose an instrument'}
            onChange={(chosenString: null | string) => {
              dbg.log('Chose instrument ' + chosenString);
              if (chosenString) {
                const element = instrumentsMap.get(chosenString);
                if (element) {
                  const instAny: InstrumentElement = element as InstrumentElement;
                  setInstrument(instAny);
                } else {
                  if (chosenString === CHROMATIC) {
                    setInstrument(ChromaticInst);
                  }
                }
              }
            }}
            doRender={MyChooser_renderInstrument}
          />
        </div>
        {instrument && instrument.name !== CHROMATIC && (
          <div style={{flexDirection: 'column', padding: 20, width: '100%'}}>
            <MyChooser
              options={subInstrumentNames}
              label="Choose instrument type"
              onChange={(chosenSubInstrumentString: null | string) => {
                dbg.log('Chose subinstrument ' + chosenSubInstrumentString);
                if (chosenSubInstrumentString) {
                  const element:
                    | undefined
                    | ElementBase = subInstrumentList.find(
                    (value: ElementBase) => {
                      return value.name === chosenSubInstrumentString;
                    },
                  );
                  if (element) {
                    const s: SubInstrumentElement = element as SubInstrumentElement;
                    setSubInstrument(s);
                  }
                }
              }}
              doRender={MyChooser_renderInstrument}
            />
          </div>
        )}
        {instrument && subInstrument && (
          <div style={{flexDirection: 'column', padding: 20, width: '100%'}}>
            <MyChooser
              options={tuningNames}
              label={'Choose tuning'}
              onChange={(_element: null | string) => {
                dbg.logObj('Chose tuning ' + _element);
                if (_element) {
                  const element: undefined | ElementBase = tuningList.find(
                    (value: ElementBase) => {
                      return value.name === _element;
                    },
                  );
                  if (element) {
                    const t: TuningElement = element as TuningElement;
                    setTuning(t);
                  }
                }
              }}
              doRender={MyChooser_renderInstrument}
            />
          </div>
        )}
      </div>
      <div className="Small" style={{flexDirection: 'row', flex: 0.2}}>
        {tuning && <div>{listOfNotesAtBottom}</div>}
      </div>
      <BottomNavigation
        style={{width: '100%', paddingBottom: 10, paddingTop: 10}}
        onChange={(_event, newValue) => {
          switch (newValue) {
            case 0:
              // Accept
              if (instrument?.name && subInstrument?.name && tuning?.name) {
                dispatch(
                  Dispatch.setCurrentInstrumentByName(
                    instrument.name,
                    subInstrument.name,
                    tuning.name,
                  ),
                );
              } else {
                if (instrument?.name === CHROMATIC) {
                  dispatch(Dispatch.setCurrentInstrumentByName('', '', ''));
                }
              }
              props.onClose();
              break;

            case 1:
              // Cancel
              props.onClose();
              break;
            default:
              break;
          }
        }}
        showLabels
        // className={classes.root}
      >
        {applyButtonEnabled && (
          <BottomNavigationAction
            label="Apply"
            icon={<DoneOutlineIcon />}
            {...help.mouseAndFocusEvents(applyButtonHelp)}
          />
        )}

        <BottomNavigationAction
          label="Cancel"
          icon={<CancelIcon />}
          {...help.mouseAndFocusEvents('Exit without choosing instrument')}
        />
      </BottomNavigation>
      <div className="Small" style={{flexDirection: 'row', flex: 0.1}}></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      ></div>
      {help.Snackbar}
    </Dialog>
  );
}
