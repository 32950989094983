// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/database';

import {call, takeEvery, takeLatest} from 'redux-saga/effects';
import {dbg, isDebug} from '@common/debug/debug';
import {AsyncChangeSources, AsyncMemory, Memories} from '@common/async_storage';
import {
  convertDateToUTC,
  DateTimeToString,
  getTimestamp,
} from '@common/utils/utils';
import {
  ActionNames,
  Dispatch,
  InfoNames,
  ReduxActions,
} from '@common/redux/redux';
import {Store} from '@abstractions/redux_inst';
import {
  TuningElement,
  InstrumentElement,
} from '@common/instruments/instrument_defs';
import {NoteMappings} from '@common/instruments/note_mapping';

export const FbTingsToStore = {
  UserEditedInstruments: 'UserInstruments',
};

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD3Rg2v30ZN_yHyBZJegUCJtt75OVPf9B8',
  authDomain: 'strobo-pro.firebaseapp.com',
  projectId: 'strobo-pro',
  storageBucket: 'strobo-pro.appspot.com',
  messagingSenderId: '587356447474',
  appId: '1:587356447474:web:7290c567363805170ee66b',
  measurementId: 'G-73ZRM4HG2H',
};

type FirebaseUserPrivate = {
  uid: string;
  timestampSeconds: string;
  timestamp: string;
};

// --------------------------------------------------------------------------
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db: firebase.database.Database = firebase
  .app()
  .database(
    'https://strobo-pro-default-rtdb.europe-west1.firebasedatabase.app',
  );

let _inst: null | FirebaseClass = null;
class FirebaseClass {
  static inst(): FirebaseClass {
    if (null === _inst) {
      _inst = new FirebaseClass();
    }
    return _inst;
  }

  // ---
  hi() {
    dbg.log('hello');
  }
}

// --------------------------------------------------------------------------
function dbgSettingsMap(
  settingsMap: Map<string, string | TuningElement[]>,
  dbgTag: string = 'mSettingsMap',
) {
  if (isDebug) {
    const keys: IterableIterator<string> = settingsMap.keys();

    // Print contents of mSettingsMap
    for (const key of keys) {
      const value = settingsMap.get(key);
      if (value) {
        dbg.log(
          dbgTag + '::key:',
          key,
          'value:',
          JSON.stringify(value, null, 2),
        );
      } else {
        dbg.log(dbgTag + '::key:', key, 'value was null');
      }
    }
  }
}

// --------------------------------------------------------------------------
function getSettingsObject(
  settingsMap: Map<string, string | TuningElement[]>,
): any {
  let settingsObj: any = {};

  dbgSettingsMap(settingsMap, 'getSettingsObject DEBUG');

  const keys: IterableIterator<string> = settingsMap.keys();

  for (const key of keys) {
    const item = settingsMap.get(key);
    if (item) {
      settingsObj = {...settingsObj, [key]: item};
    } else {
      dbg.log('getSettingsObject::key:', key, 'was null');
    }
  }
  return settingsObj;
}

// --------------------------------------------------------------------------
let firebaseAsyncInst: FirebaseAsyncStorage | null = null;
export class FirebaseAsyncStorage extends AsyncMemory {
  mLoggedIn = false;
  mDirty = false;
  mInstrumentDirty = false;
  mUid = '';
  mThereWasDataOnFirebase = false;
  mSettingsMap: Map<string, string> = new Map();
  mMyInstruments: TuningElement[] = [];
  mDefaultInstrumentsLoaded = false;
  mFirebaseInstrumentsLoaded = false;
  mDataLoadedFromFirebase = false;
  static inst(): FirebaseAsyncStorage {
    if (null === firebaseAsyncInst) {
      firebaseAsyncInst = new FirebaseAsyncStorage();
    }
    return firebaseAsyncInst;
  }

  isLoggedIn(): boolean {
    return this.mUid.length > 0;
  }

  setDefaultInstrumentsAreLoaded() {
    this.mDefaultInstrumentsLoaded = true;
    this.pollLoadOfInstrumentsFromFirebase();
  }

  pollLoadOfInstrumentsFromFirebase() {
    if (
      this.mUid.length > 0 &&
      this.mDefaultInstrumentsLoaded &&
      !this.mFirebaseInstrumentsLoaded
    ) {
      this.mFirebaseInstrumentsLoaded = true;

      if (this.mMyInstruments.length > 0) {
        const serializedTunings = [...this.mMyInstruments];
        const arrayOfNewInstruments = NoteMappings.inst().deSerializeModifiedTunings(
          serializedTunings,
        );
        dbg.log('arrayOfNewInstruments:', arrayOfNewInstruments);
        arrayOfNewInstruments.forEach((instrument: InstrumentElement) => {
          Store.inst().store.dispatch(
            Dispatch.addInstrument(instrument, undefined, true),
          );
        });
        Store.inst().store.dispatch(Dispatch.setFirebaseLoadCompleted());
      }
    }
  }

  // --------------------------------------------------------------------------
  scheduleSyncToFirebase() {
    if (this.mUid.length > 0) {
      setTimeout(() => {
        if (this.mDirty && this.mDataLoadedFromFirebase) {
          this._sendSettingsToFirebase();
        }

        if (this.mInstrumentDirty && this.mDataLoadedFromFirebase) {
          this._sendInstrumentsToFirebase();
        }
      }, 2000);
    }
  }

  // --------------------------------------------------------------------------
  _sendSettingsToFirebase() {
    this.mDirty = false;

    const settingsObj: any = getSettingsObject(this.mSettingsMap);
    dbg.logObj('FirebaseAsyncStorage::scheduleSyncToFirebase()', settingsObj);

    db.ref()
      .child('/users_settings/' + this.mUid + '/devices/web/')
      .set(settingsObj)
      .then(() => {
        'FirebaseAsyncStorage::Set settings OK.';
      })
      .catch((reason: any) => {
        console.warn(
          'FirebaseAsyncStorage::Error when setting user ' +
            this.mUid +
            ' settings. ',
          reason,
        );
      });
  }
  // --------------------------------------------------------------------------
  _sendInstrumentsToFirebase() {
    this.mInstrumentDirty = false;

    dbg.log('FirebaseAsyncStorage::sendInstrumentsToFirebase()');
    dbg.logObj(
      'FirebaseAsyncStorage::sendInstrumentsToFirebase()',
      this.mMyInstruments,
    );
    db.ref()
      .child('/users_settings/' + this.mUid + '/UserInstruments')
      .set(this.mMyInstruments)
      .then(() => {
        'FirebaseAsyncStorage::Set instruments OK.';
      })
      .catch((reason: any) => {
        console.warn(
          'FirebaseAsyncStorage::Error when setting user ' +
            this.mUid +
            ' settings. ',
          reason,
        );
      });
  }

  deleteAllUserData(): Promise<void> {
    const p = new Promise<void>((resolve, _reject) => {
      if (this.mUid.length > 0) {
        dbg.log('FirebaseAsyncStorage::Deleting user ' + this.mUid);
        db.ref()
          .child('/users_settings/' + this.mUid)
          .remove()
          .then(() => {
            dbg.log(
              'FirebaseAsyncStorage::Deleted user ' + this.mUid + ' settings.',
            );
            db.ref()
              .child('/users_private/' + this.mUid)
              .remove()
              .then(() => {
                dbg.log(
                  'FirebaseAsyncStorage::Deleted user ' +
                    this.mUid +
                    ' private.',
                );
                firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    resolve();
                  });
              })
              .catch((reason: any) => {
                dbg.warn(
                  'FirebaseAsyncStorage::Error when deleting user ' +
                    this.mUid +
                    ' private. ',
                  reason,
                );
              })
              .finally(() => {
                firebase.auth().signOut();
              });
          })
          .catch((reason: any) => {
            dbg.warn(
              'FirebaseAsyncStorage::Error when deleting user ' +
                this.mUid +
                ' settings. ',
              reason,
            );
          });
      }
    });
    return p;
  }

  // --------------------------------------------------------------------------
  async _onAuthStatusChangedPromise(uid: string) {
    this.mUid = uid;
    this.mLoggedIn = uid.length > 0;
    dbg.log('FirebaseAsyncStorage::onAuthStatusChanged(uid=' + uid + ')');
    if (this.mUid.length === 0) {
      Memories.inst().removeMemory(AsyncChangeSources.firebase);
    } else {
      const userSettings: firebase.database.DataSnapshot = await db
        .ref()
        .child('/users_settings/' + this.mUid + '/devices/web/')
        .once('value');

      const settingsIn = userSettings.val();
      if (settingsIn) {
        for (const [attr, value] of Object.entries(settingsIn)) {
          if (value) {
            const v: any = value;
            this.mSettingsMap.set(attr, v);
            this.mThereWasDataOnFirebase = true;
          }
        }
      }

      const instruments: firebase.database.DataSnapshot = await db
        .ref()
        .child('/users_settings/' + this.mUid + '/UserInstruments')
        .once('value');

      const instrumentsIn: null | [TuningElement] = instruments.val();
      dbg.logObj('instrumentsIn:', instrumentsIn);

      if (instrumentsIn && instrumentsIn.length > 0 && instrumentsIn[0].name) {
        this.mMyInstruments = [...instrumentsIn];
      }

      this.mDataLoadedFromFirebase = true;
      Memories.inst().addMemory(AsyncChangeSources.firebase, this);

      this.pollLoadOfInstrumentsFromFirebase();
    }
  }

  onAuthStatusChanged(uid: string) {
    this._onAuthStatusChangedPromise(uid)
      .then(() => {
        dbg.log('FirebaseAsyncStorage::onAuthStatusChanged()');
      })
      .catch((reason: any) => {
        console.warn(
          'FirebaseAsyncStorage::onAuthStatusChanged() error:',
          reason,
        );
      });
  }

  // --------------------------------------------------------------------------
  setItem(
    key: string,
    item: string,
    source: AsyncChangeSources = AsyncChangeSources.user,
  ): Promise<void> {
    dbg.log(
      'FirebaseAsyncStorage::setItem() from source' + source.toString(),
      key,
      item,
    );
    const p = new Promise<void>((resolve, reject) => {
      if (this.mThereWasDataOnFirebase && source === AsyncChangeSources.nvm) {
        dbg.log(
          'FirebaseAsyncStorage::setItem() ignored as source was NVM' +
            source.toString(),
          key,
          item,
        );
      } else {
        if (key !== FbTingsToStore.UserEditedInstruments) {
          dbg.log('setting key', key);
          // this.mSettings[key] = item;
          this.mSettingsMap.set(key, item);
          this.mDirty = true;
          this.scheduleSyncToFirebase();
        } else {
          console.error(
            'FirebaseAsyncStorage::setItem() key was UserEditedInstruments',
          );
        }
      }
      resolve();
    });
    return p;
  }

  // --------------------------------------------------------------------------
  setInstruments(tuningsArr: TuningElement[]) {
    if (!!tuningsArr) {
      this.pollLoadOfInstrumentsFromFirebase();
      if (this.mDefaultInstrumentsLoaded && this.mFirebaseInstrumentsLoaded) {
        try {
          dbg.logObj('Saving instruments:', tuningsArr);

          this.mMyInstruments = [...tuningsArr];

          this.mInstrumentDirty = true;
          this.scheduleSyncToFirebase();
        } catch (e: any) {
          console.error(e.message, e);
        }
      }
    }
  }

  // --------------------------------------------------------------------------
  getItem(key: string): Promise<string | null> {
    dbg.log('FirebaseAsyncStorage::getItem()', key);
    const p = new Promise<string | null>((resolve, reject) => {
      // const value: string | undefined = this.mSettings[key];
      const value = this.mSettingsMap.get(key);
      if (value) {
        resolve(value.toString());
      } else {
        dbg.log('FirebaseAsyncStorage::Not logged in or nothing saved');
        resolve(null);
      }
    });
    return p;
  }
}

function FirebaseMiddleWare(_store: any) {
  return (next: any) => {
    return (actionAny: any /*ReduxActions*/) => {
      const action: ReduxActions = actionAny;
      switch (action.type) {
        // case 'AddInstrumentAction': {
        // Intercept the instrument being added and make it searchable from child to parent.
        //  const addInstrument: AddInstrumentAction = actionAny;
        //  const instrumentAny: any = addInstrument.instrument;
        //  recursiveAddParents(instrumentAny);
        //  break;
        // }
        default:
          break;
      }

      // continue processing this action
      return next(action);
    };
  };
}

// --------------------------------------------------------------------------
function* onAppInit(actionAny: any, ..._otherArgs: any[]) {
  // Just create the singleton.
  const fb = FirebaseClass.inst();
  fb.hi();

  // firebase.analytics();
  firebase.auth().onAuthStateChanged(user => {
    const isSignedIn = !!user;

    const _uid = isSignedIn ? user?.uid : '';
    const uid: string = _uid ? _uid : '';

    FirebaseAsyncStorage.inst().onAuthStatusChanged(uid);

    dbg.log('Firebase::isSignedIn = ', isSignedIn, uid);
    Store.inst().store.dispatch(Dispatch.setFirebaseUid(uid));
    if (isSignedIn) {
      dbg.log('Firebase::Storing new private data for uid ', uid);
      const priv: FirebaseUserPrivate = {
        uid,
        timestampSeconds: getTimestamp(),
        timestamp: DateTimeToString(convertDateToUTC(new Date()))
      };
      db.ref()
        .child('/users_private/' + uid + '/')
        .set(priv)
        .then((_value: any) => {
          dbg.log('Firebase::Wrote data ', priv);
        })
        .catch((reason: any) => {
          console.warn(
            'Firebase::Could not write to database, reason = ',
            reason,
          );
        });
    }
  });

  yield 0;
}

function* onAppInitEffect() {
  yield takeEvery(ActionNames.InitAction, onAppInit);
}

// HANDLE Aync Storage Load Completed.
function* onAsyncStorageLoadCompleted() {
  const handleAsyncStorageLoadCompleted = () => {
    dbg.log('firebase.ts::Placeholder::handleAsyncStorageLoadCompleted');
  };

  yield takeLatest(
    InfoNames.AsyncStorageLoadCompleted,
    handleAsyncStorageLoadCompleted,
  );
}

// -----------------------------------------------------------------------------
function* onInstrumentsUpdated(actionAny: any, ..._otherArgs: any[]) {
  const saveEm = (_actionAny: any) => {
    const jsonArr = NoteMappings.inst().serializeModifiedTunings();
    if (jsonArr.length > 0) {
      FirebaseAsyncStorage.inst().setInstruments(jsonArr);
    }
  };

  if (actionAny.type === InfoNames.DefaultInstrumentsLoadCompleted) {
    FirebaseAsyncStorage.inst().setDefaultInstrumentsAreLoaded();
  }

  yield call(saveEm, actionAny);
}

// -----------------------------------------------------------------------------
function* onInstrumentsUpdatedEffect() {
  dbg.log('async::onInstrumentsUpdatedEffect()');
  yield takeEvery(
    InfoNames.DefaultInstrumentsLoadCompleted,
    onInstrumentsUpdated,
  );
}

// -----------------------------------------------------------------------------
function* onQueueSaveInstrumentsToNvmEffect() {
  dbg.log('async::onQueueSaveInstrumentsToNvmEffect()');
  yield takeEvery(InfoNames.QueueSaveInstrumentsToNvm, onInstrumentsUpdated);
}
const FirebaseSideEffects = [
  onAppInitEffect,
  onAsyncStorageLoadCompleted,
  onInstrumentsUpdatedEffect,
  onQueueSaveInstrumentsToNvmEffect,
];

export {FirebaseClass, firebase, FirebaseMiddleWare, FirebaseSideEffects};
