import {combineReducers, createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';

import {FirebaseMiddleWare, FirebaseSideEffects} from '@abstractions/firebase';
import {dbg} from '../../debug/debug';

import {
  NoteMappingMiddleWare,
  NoteMappingSideEffects,
} from '../../instruments/note_mapping';
import {Dispatch, systemReducer} from '../../redux/redux';
import {AsyncStorageSideEffects} from '../../async_storage';
import {AudioInSideEffects} from './audio_in';
import {StroboProCpp} from './wrapper/js_wrapper';

const rootReducer = combineReducers({
  system: systemReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const sagaMiddleware = createSagaMiddleware();

// store.js
function configureStore(initialState = {}) {
  // dbg.log('configureStore');
  const newStore = createStore(
    rootReducer,
    initialState,
    applyMiddleware(NoteMappingMiddleWare, FirebaseMiddleWare, sagaMiddleware),
  );
  return newStore;
}
const _store = configureStore();

// Run any listeners that will trigger from actions....
// TODO move to a smarter part.
[
  NoteMappingSideEffects,
  FirebaseSideEffects,
  AsyncStorageSideEffects,
  AudioInSideEffects,
].forEach((arr: any[]) => {
  arr.forEach(value => {
    sagaMiddleware.run(value);
  });
});

let storeInst: Store | null = null;
export class Store {
  store = _store;

  static inst(): Store {
    if (storeInst === null) {
      storeInst = new Store();

      setTimeout(() => {
        StroboProCpp.inst()._tunerPromise?.then(() => {
          try {
            _store.dispatch(Dispatch.init());
          } catch (e: any) {
            dbg.log('Got error when dispatching ', JSON.stringify(e, null, 2));
          }
        });
      }, 0);
    }
    return storeInst;
  }
}

export const store = Store.inst().store;
export type AppDispatch = typeof store.dispatch;
