import { TuningElement } from './instrument_defs';

export const DefaultTemperamentArray: TuningElement[] = [
  {
    name: 'None (Equal, Perfect Oct)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '0.0000',
      },
      {
        name: 'B',
        cents: '0.0000',
      },
      {
        name: 'C',
        cents: '0.0000',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '0.0000',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '0.0000',
      },
      {
        name: 'F',
        cents: '0.0000',
      },
      {
        name: 'F#',
        cents: '0.0000',
      },
      {
        name: 'G',
        cents: '0.0000',
      },
      {
        name: 'G#',
        cents: '0.0000',
      },
    ],
  },
  {
    name: 'Equal, Perfect Fourth',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-0.3910',
      },
      {
        name: 'B',
        cents: '-0.7820',
      },
      {
        name: 'C',
        cents: '3.5190',
      },
      {
        name: 'C#',
        cents: '3.1280',
      },
      {
        name: 'D',
        cents: '2.7370',
      },
      {
        name: 'D#',
        cents: '2.3460',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '1.5640',
      },
      {
        name: 'F#',
        cents: '1.1730',
      },
      {
        name: 'G',
        cents: '0.7820',
      },
      {
        name: 'G#',
        cents: '0.3910',
      },
    ],
  },
  {
    name: 'Equal, Perfect Fifth',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '0.2790',
      },
      {
        name: 'B',
        cents: '0.5580',
      },
      {
        name: 'C',
        cents: '-2.5140',
      },
      {
        name: 'C#',
        cents: '-2.2350',
      },
      {
        name: 'D',
        cents: '-1.9560',
      },
      {
        name: 'D#',
        cents: '-1.6770',
      },
      {
        name: 'E',
        cents: '-1.3970',
      },
      {
        name: 'F',
        cents: '-1.1180',
      },
      {
        name: 'F#',
        cents: '-0.8380',
      },
      {
        name: 'G',
        cents: '-0.5590',
      },
      {
        name: 'G#',
        cents: '-0.2800',
      },
    ],
  },
  {
    name: 'Equal, Stretched (1.0 Cent)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '0.0830',
      },
      {
        name: 'B',
        cents: '0.1670',
      },
      {
        name: 'C',
        cents: '-0.7500',
      },
      {
        name: 'C#',
        cents: '-0.6670',
      },
      {
        name: 'D',
        cents: '-0.5830',
      },
      {
        name: 'D#',
        cents: '-0.5000',
      },
      {
        name: 'E',
        cents: '-0.4170',
      },
      {
        name: 'F',
        cents: '-0.3330',
      },
      {
        name: 'F#',
        cents: '-0.2500',
      },
      {
        name: 'G',
        cents: '-0.1670',
      },
      {
        name: 'G#',
        cents: '-0.0830',
      },
    ],
  },
  {
    name: 'Equal, Stretched (1.25 Cent)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '0.1040',
      },
      {
        name: 'B',
        cents: '0.2080',
      },
      {
        name: 'C',
        cents: '-0.9380',
      },
      {
        name: 'C#',
        cents: '-0.8340',
      },
      {
        name: 'D',
        cents: '-0.7300',
      },
      {
        name: 'D#',
        cents: '-0.6250',
      },
      {
        name: 'E',
        cents: '-0.5210',
      },
      {
        name: 'F',
        cents: '-0.4170',
      },
      {
        name: 'F#',
        cents: '-0.3130',
      },
      {
        name: 'G',
        cents: '-0.2090',
      },
      {
        name: 'G#',
        cents: '-0.1050',
      },
    ],
  },
  {
    name: 'Equal, Stretched (1.5 Cent)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '0.1250',
      },
      {
        name: 'B',
        cents: '0.2500',
      },
      {
        name: 'C',
        cents: '-1.1250',
      },
      {
        name: 'C#',
        cents: '-1.0000',
      },
      {
        name: 'D',
        cents: '-0.8750',
      },
      {
        name: 'D#',
        cents: '-0.7500',
      },
      {
        name: 'E',
        cents: '-0.6250',
      },
      {
        name: 'F',
        cents: '-0.5000',
      },
      {
        name: 'F#',
        cents: '-0.3750',
      },
      {
        name: 'G',
        cents: '-0.2500',
      },
      {
        name: 'G#',
        cents: '-0.1250',
      },
    ],
  },
  {
    name: 'Agricola (Martin, 1539, 1543, 1545)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-13.6860',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-9.7760',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '-15.6410',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-11.7310',
      },
    ],
  },
  {
    name: 'Ammerbach (1571)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '4.8880',
      },
      {
        name: 'B',
        cents: '-1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '-7.8200',
      },
      {
        name: 'D',
        cents: '3.9100',
      },
      {
        name: 'D#',
        cents: '8.7980',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '-3.9100',
      },
      {
        name: 'G',
        cents: '7.8200',
      },
      {
        name: 'G#',
        cents: '-9.7750',
      },
    ],
  },
  {
    name: 'Ammerbach (1583, Interp. 1)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '8.2250',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '6.1350',
      },
      {
        name: 'C#',
        cents: '-4.1800',
      },
      {
        name: 'D',
        cents: '4.0450',
      },
      {
        name: 'D#',
        cents: '6.2700',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '4.1800',
      },
      {
        name: 'F#',
        cents: '-0.1350',
      },
      {
        name: 'G',
        cents: '8.0900',
      },
      {
        name: 'G#',
        cents: '-2.2250',
      },
    ],
  },
  {
    name: 'Ammerbach (1583, Interp. 2)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.2250',
      },
      {
        name: 'B',
        cents: '-2.0900',
      },
      {
        name: 'C',
        cents: '6.1350',
      },
      {
        name: 'C#',
        cents: '-8.1800',
      },
      {
        name: 'D',
        cents: '4.0450',
      },
      {
        name: 'D#',
        cents: '9.2700',
      },
      {
        name: 'E',
        cents: '-2.0450',
      },
      {
        name: 'F',
        cents: '4.1800',
      },
      {
        name: 'F#',
        cents: '-4.1350',
      },
      {
        name: 'G',
        cents: '8.0900',
      },
      {
        name: 'G#',
        cents: '-10.2250',
      },
    ],
  },
  {
    name: 'Bach (Billeter, Well-Tempered)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '2.9330',
      },
      {
        name: 'B',
        cents: '-4.8870',
      },
      {
        name: 'C',
        cents: '4.8880',
      },
      {
        name: 'C#',
        cents: '-2.9320',
      },
      {
        name: 'D',
        cents: '4.8880',
      },
      {
        name: 'D#',
        cents: '0.9780',
      },
      {
        name: 'E',
        cents: '-4.8870',
      },
      {
        name: 'F',
        cents: '4.8880',
      },
      {
        name: 'F#',
        cents: '-4.8870',
      },
      {
        name: 'G',
        cents: '4.8880',
      },
      {
        name: 'G#',
        cents: '-0.9770',
      },
    ],
  },
  {
    name: 'Bach (Kelletats, 1966)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.2140',
      },
      {
        name: 'B',
        cents: '-4.5610',
      },
      {
        name: 'C',
        cents: '9.1240',
      },
      {
        name: 'C#',
        cents: '-0.6510',
      },
      {
        name: 'D',
        cents: '4.5630',
      },
      {
        name: 'D#',
        cents: '3.2590',
      },
      {
        name: 'E',
        cents: '-4.5630',
      },
      {
        name: 'F',
        cents: '7.1690',
      },
      {
        name: 'F#',
        cents: '-2.6060',
      },
      {
        name: 'G',
        cents: '9.1260',
      },
      {
        name: 'G#',
        cents: '1.3040',
      },
    ],
  },
  {
    name: 'Bach (Kellner, 1977)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '4.3010',
      },
      {
        name: 'B',
        cents: '-0.7820',
      },
      {
        name: 'C',
        cents: '8.2110',
      },
      {
        name: 'C#',
        cents: '-1.5640',
      },
      {
        name: 'D',
        cents: '2.7370',
      },
      {
        name: 'D#',
        cents: '2.3460',
      },
      {
        name: 'E',
        cents: '-2.7370',
      },
      {
        name: 'F',
        cents: '6.2560',
      },
      {
        name: 'F#',
        cents: '-3.5190',
      },
      {
        name: 'G',
        cents: '5.4740',
      },
      {
        name: 'G#',
        cents: '0.3910',
      },
    ],
  },
  {
    name: 'Bach (Kellner, Well-Tempered)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.8640',
      },
      {
        name: 'B',
        cents: '-1.3030',
      },
      {
        name: 'C',
        cents: '9.7740',
      },
      {
        name: 'C#',
        cents: '-0.0010',
      },
      {
        name: 'D',
        cents: '3.2580',
      },
      {
        name: 'D#',
        cents: '3.9090',
      },
      {
        name: 'E',
        cents: '-3.2580',
      },
      {
        name: 'F',
        cents: '7.8190',
      },
      {
        name: 'F#',
        cents: '-1.9560',
      },
      {
        name: 'G',
        cents: '6.5160',
      },
      {
        name: 'G#',
        cents: '1.9540',
      },
    ],
  },
  {
    name: 'Bach (Klais)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.7700',
      },
      {
        name: 'B',
        cents: '-5.7000',
      },
      {
        name: 'C',
        cents: '7.4910',
      },
      {
        name: 'C#',
        cents: '-1.9530',
      },
      {
        name: 'D',
        cents: '3.7410',
      },
      {
        name: 'D#',
        cents: '1.8520',
      },
      {
        name: 'E',
        cents: '-4.8780',
      },
      {
        name: 'F',
        cents: '5.6510',
      },
      {
        name: 'F#',
        cents: '-3.8150',
      },
      {
        name: 'G',
        cents: '7.5120',
      },
      {
        name: 'G#',
        cents: '-0.0200',
      },
    ],
  },
  {
    name: 'Bagpipe',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '0.0000',
      },
      {
        name: 'B',
        cents: '3.9000',
      },
      {
        name: 'C',
        cents: '0.0000',
      },
      {
        name: 'C#',
        cents: '-13.7000',
      },
      {
        name: 'D',
        cents: '-1.9000',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '2.0000',
      },
      {
        name: 'F',
        cents: '0.0000',
      },
      {
        name: 'F#',
        cents: '-15.6000',
      },
      {
        name: 'G',
        cents: '-31.2000',
      },
      {
        name: 'G#',
        cents: '0.0000',
      },
    ],
  },
  {
    name: 'Barnes (1971)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9560',
      },
      {
        name: 'B',
        cents: '-1.9550',
      },
      {
        name: 'C',
        cents: '2.9330',
      },
      {
        name: 'C#',
        cents: '-0.9770',
      },
      {
        name: 'D',
        cents: '0.9780',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '-0.9770',
      },
      {
        name: 'F',
        cents: '3.9110',
      },
      {
        name: 'F#',
        cents: '-2.9320',
      },
      {
        name: 'G',
        cents: '1.9560',
      },
      {
        name: 'G#',
        cents: '0.9780',
      },
    ],
  },
  {
    name: 'Barnes (1977)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.8650',
      },
      {
        name: 'B',
        cents: '0.0000',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '3.9100',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '7.8200',
      },
      {
        name: 'F#',
        cents: '-1.9550',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Bendeler (Fractions)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '6.5460',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '10.4560',
      },
      {
        name: 'C#',
        cents: '0.6810',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '4.5910',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '8.5010',
      },
      {
        name: 'F#',
        cents: '-1.2740',
      },
      {
        name: 'G',
        cents: '3.2290',
      },
      {
        name: 'G#',
        cents: '2.6360',
      },
    ],
  },
  {
    name: 'Bendeler III',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9550',
      },
      {
        name: 'B',
        cents: '-1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '1.9550',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '0.0000',
      },
      {
        name: 'G',
        cents: '1.9550',
      },
      {
        name: 'G#',
        cents: '3.9100',
      },
    ],
  },
  {
    name: 'Bermudo (1555)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-5.8650',
      },
      {
        name: 'B',
        cents: '0.0000',
      },
      {
        name: 'C',
        cents: '-1.9550',
      },
      {
        name: 'C#',
        cents: '-1.9550',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-7.8200',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '-3.9100',
      },
      {
        name: 'F#',
        cents: '-3.9100',
      },
      {
        name: 'G',
        cents: '0.0000',
      },
      {
        name: 'G#',
        cents: '0.0000',
      },
    ],
  },
  {
    name: 'Bossart I',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '13.6850',
      },
      {
        name: 'B',
        cents: '-4.8880',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '3.9100',
      },
      {
        name: 'D#',
        cents: '14.6630',
      },
      {
        name: 'E',
        cents: '-3.9100',
      },
      {
        name: 'F',
        cents: '9.7750',
      },
      {
        name: 'F#',
        cents: '-5.8650',
      },
      {
        name: 'G',
        cents: '4.8880',
      },
      {
        name: 'G#',
        cents: '0.0000',
      },
    ],
  },
  {
    name: 'Bossart II',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '10.7530',
      },
      {
        name: 'B',
        cents: '1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '0.9780',
      },
      {
        name: 'D#',
        cents: '14.6630',
      },
      {
        name: 'E',
        cents: '0.0000',
      },
      {
        name: 'F',
        cents: '9.7750',
      },
      {
        name: 'F#',
        cents: '-1.9550',
      },
      {
        name: 'G',
        cents: '4.8880',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Bossart III',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '10.7530',
      },
      {
        name: 'B',
        cents: '-4.8880',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '-0.9780',
      },
      {
        name: 'D',
        cents: '3.9100',
      },
      {
        name: 'D#',
        cents: '11.7300',
      },
      {
        name: 'E',
        cents: '-3.9100',
      },
      {
        name: 'F',
        cents: '9.7750',
      },
      {
        name: 'F#',
        cents: '-2.9330',
      },
      {
        name: 'G',
        cents: '4.8880',
      },
      {
        name: 'G#',
        cents: '2.9320',
      },
    ],
  },
  {
    name: 'Bruder (1829)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '0.9780',
      },
      {
        name: 'B',
        cents: '-4.8870',
      },
      {
        name: 'C',
        cents: '2.9330',
      },
      {
        name: 'C#',
        cents: '-1.9550',
      },
      {
        name: 'D',
        cents: '5.8660',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '-5.8650',
      },
      {
        name: 'F',
        cents: '1.9550',
      },
      {
        name: 'F#',
        cents: '-3.4220',
      },
      {
        name: 'G',
        cents: '4.3990',
      },
      {
        name: 'G#',
        cents: '-0.9770',
      },
    ],
  },
  {
    name: 'Comma - Temperament (1/10)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '0.9800',
      },
      {
        name: 'B',
        cents: '-0.3920',
      },
      {
        name: 'C',
        cents: '0.5880',
      },
      {
        name: 'C#',
        cents: '-0.7840',
      },
      {
        name: 'D',
        cents: '0.1960',
      },
      {
        name: 'D#',
        cents: '1.1760',
      },
      {
        name: 'E',
        cents: '-0.1960',
      },
      {
        name: 'F',
        cents: '0.7840',
      },
      {
        name: 'F#',
        cents: '-0.5880',
      },
      {
        name: 'G',
        cents: '0.3920',
      },
      {
        name: 'G#',
        cents: '-0.9800',
      },
    ],
  },
  {
    name: 'Comma - Temperament (1/7)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.5850',
      },
      {
        name: 'B',
        cents: '-2.2340',
      },
      {
        name: 'C',
        cents: '3.3510',
      },
      {
        name: 'C#',
        cents: '-4.4680',
      },
      {
        name: 'D',
        cents: '1.1170',
      },
      {
        name: 'D#',
        cents: '6.7020',
      },
      {
        name: 'E',
        cents: '-1.1170',
      },
      {
        name: 'F',
        cents: '4.4680',
      },
      {
        name: 'F#',
        cents: '-3.3510',
      },
      {
        name: 'G',
        cents: '2.2340',
      },
      {
        name: 'G#',
        cents: '-5.5850',
      },
    ],
  },
  {
    name: 'Comma - Temperament (1/8)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.6650',
      },
      {
        name: 'B',
        cents: '-1.4660',
      },
      {
        name: 'C',
        cents: '2.1990',
      },
      {
        name: 'C#',
        cents: '-2.9320',
      },
      {
        name: 'D',
        cents: '0.7330',
      },
      {
        name: 'D#',
        cents: '4.3980',
      },
      {
        name: 'E',
        cents: '-0.7330',
      },
      {
        name: 'F',
        cents: '2.9320',
      },
      {
        name: 'F#',
        cents: '-2.1990',
      },
      {
        name: 'G',
        cents: '1.4660',
      },
      {
        name: 'G#',
        cents: '-3.6650',
      },
    ],
  },
  {
    name: 'Comma - Temperament (1/9)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '2.1750',
      },
      {
        name: 'B',
        cents: '-0.8700',
      },
      {
        name: 'C',
        cents: '1.3050',
      },
      {
        name: 'C#',
        cents: '-1.7400',
      },
      {
        name: 'D',
        cents: '0.4350',
      },
      {
        name: 'D#',
        cents: '2.6100',
      },
      {
        name: 'E',
        cents: '-0.4350',
      },
      {
        name: 'F',
        cents: '1.7400',
      },
      {
        name: 'F#',
        cents: '-1.3050',
      },
      {
        name: 'G',
        cents: '0.8700',
      },
      {
        name: 'G#',
        cents: '-2.1750',
      },
    ],
  },
  {
    name: 'Comma - Temperament (2/9)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '14.1200',
      },
      {
        name: 'B',
        cents: '-5.6480',
      },
      {
        name: 'C',
        cents: '8.4720',
      },
      {
        name: 'C#',
        cents: '-11.2960',
      },
      {
        name: 'D',
        cents: '2.8240',
      },
      {
        name: 'D#',
        cents: '16.9440',
      },
      {
        name: 'E',
        cents: '-2.8240',
      },
      {
        name: 'F',
        cents: '11.2960',
      },
      {
        name: 'F#',
        cents: '-8.4720',
      },
      {
        name: 'G',
        cents: '5.6480',
      },
      {
        name: 'G#',
        cents: '-14.1200',
      },
    ],
  },
  {
    name: 'Comma - Temperament (3/11)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '19.5500',
      },
      {
        name: 'B',
        cents: '-7.8200',
      },
      {
        name: 'C',
        cents: '11.7300',
      },
      {
        name: 'C#',
        cents: '-15.6400',
      },
      {
        name: 'D',
        cents: '3.9100',
      },
      {
        name: 'D#',
        cents: '23.4600',
      },
      {
        name: 'E',
        cents: '-3.9100',
      },
      {
        name: 'F',
        cents: '15.6400',
      },
      {
        name: 'F#',
        cents: '-11.7300',
      },
      {
        name: 'G',
        cents: '7.8200',
      },
      {
        name: 'G#',
        cents: '-19.5500',
      },
    ],
  },
  {
    name: 'Diatonic (Chromatic addition)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '11.7210',
      },
      {
        name: 'B',
        cents: '3.9000',
      },
      {
        name: 'C',
        cents: '15.6310',
      },
      {
        name: 'C#',
        cents: '-13.6960',
      },
      {
        name: 'D',
        cents: '19.5410',
      },
      {
        name: 'D#',
        cents: '31.2720',
      },
      {
        name: 'E',
        cents: '1.9450',
      },
      {
        name: 'F',
        cents: '13.6760',
      },
      {
        name: 'F#',
        cents: '5.8550',
      },
      {
        name: 'G',
        cents: '17.5860',
      },
      {
        name: 'G#',
        cents: '-11.7410',
      },
    ],
  },
  {
    name: 'Ganassi (1543)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-1.0460',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6410',
      },
      {
        name: 'C#',
        cents: '4.4420',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-3.0010',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '12.6410',
      },
      {
        name: 'G',
        cents: '17.5960',
      },
      {
        name: 'G#',
        cents: '6.3970',
      },
    ],
  },
  {
    name: 'Goebel (1967)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-0.0510',
      },
      {
        name: 'B',
        cents: '-0.1170',
      },
      {
        name: 'C',
        cents: '-0.1690',
      },
      {
        name: 'C#',
        cents: '0.1090',
      },
      {
        name: 'D',
        cents: '0.2080',
      },
      {
        name: 'D#',
        cents: '0.1280',
      },
      {
        name: 'E',
        cents: '0.1180',
      },
      {
        name: 'F',
        cents: '-0.1400',
      },
      {
        name: 'F#',
        cents: '0.0260',
      },
      {
        name: 'G',
        cents: '0.0200',
      },
      {
        name: 'G#',
        cents: '-0.0190',
      },
    ],
  },
  {
    name: 'Grammateus (1518)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '-5.8650',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-1.9550',
      },
    ],
  },
  {
    name: 'Just Tempered (Barbour)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '33.2370',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6410',
      },
      {
        name: 'C#',
        cents: '-13.6860',
      },
      {
        name: 'D',
        cents: '19.5510',
      },
      {
        name: 'D#',
        cents: '31.2820',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '5.8640',
      },
      {
        name: 'G',
        cents: '17.5960',
      },
      {
        name: 'G#',
        cents: '-11.7320',
      },
    ],
  },
  {
    name: 'Just Tempered (Schugk)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '33.2370',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6410',
      },
      {
        name: 'C#',
        cents: '-13.6860',
      },
      {
        name: 'D',
        cents: '19.5510',
      },
      {
        name: 'D#',
        cents: '31.2820',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '5.8640',
      },
      {
        name: 'G',
        cents: '17.5960',
      },
      {
        name: 'G#',
        cents: '29.3270',
      },
    ],
  },
  {
    name: 'Kelletat (1960)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.9100',
      },
      {
        name: 'B',
        cents: '-5.8650',
      },
      {
        name: 'C',
        cents: '7.8200',
      },
      {
        name: 'C#',
        cents: '-1.9550',
      },
      {
        name: 'D',
        cents: '3.9100',
      },
      {
        name: 'D#',
        cents: '1.9550',
      },
      {
        name: 'E',
        cents: '-4.8870',
      },
      {
        name: 'F',
        cents: '5.8650',
      },
      {
        name: 'F#',
        cents: '-3.9100',
      },
      {
        name: 'G',
        cents: '7.8200',
      },
      {
        name: 'G#',
        cents: '0.0000',
      },
    ],
  },
  {
    name: 'Kelletat (1966)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.9100',
      },
      {
        name: 'B',
        cents: '-5.8650',
      },
      {
        name: 'C',
        cents: '7.8200',
      },
      {
        name: 'C#',
        cents: '-1.9550',
      },
      {
        name: 'D',
        cents: '3.9100',
      },
      {
        name: 'D#',
        cents: '1.9550',
      },
      {
        name: 'E',
        cents: '-3.9100',
      },
      {
        name: 'F',
        cents: '5.8650',
      },
      {
        name: 'F#',
        cents: '-3.9100',
      },
      {
        name: 'G',
        cents: '7.8200',
      },
      {
        name: 'G#',
        cents: '0.0000',
      },
    ],
  },
  {
    name: 'Kellner',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '4.3010',
      },
      {
        name: 'B',
        cents: '-0.7820',
      },
      {
        name: 'C',
        cents: '8.2110',
      },
      {
        name: 'C#',
        cents: '-1.5640',
      },
      {
        name: 'D',
        cents: '2.7370',
      },
      {
        name: 'D#',
        cents: '2.3460',
      },
      {
        name: 'E',
        cents: '-2.7370',
      },
      {
        name: 'F',
        cents: '6.2560',
      },
      {
        name: 'F#',
        cents: '-3.5190',
      },
      {
        name: 'G',
        cents: '5.4740',
      },
      {
        name: 'G#',
        cents: '0.3910',
      },
    ],
  },
  {
    name: 'Kircher',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-7.8200',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6400',
      },
      {
        name: 'C#',
        cents: '-13.6850',
      },
      {
        name: 'D',
        cents: '19.5500',
      },
      {
        name: 'D#',
        cents: '-9.7750',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6850',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '17.5950',
      },
      {
        name: 'G#',
        cents: '-11.7300',
      },
    ],
  },
  {
    name: 'Kirnberger I (1766)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '11.7300',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6400',
      },
      {
        name: 'C#',
        cents: '5.8650',
      },
      {
        name: 'D',
        cents: '19.5500',
      },
      {
        name: 'D#',
        cents: '9.7750',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6850',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '17.5950',
      },
      {
        name: 'G#',
        cents: '7.8200',
      },
    ],
  },
  {
    name: 'Kirnberger II (1771)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '0.9780',
      },
      {
        name: 'B',
        cents: '-6.8430',
      },
      {
        name: 'C',
        cents: '4.8880',
      },
      {
        name: 'C#',
        cents: '-4.8870',
      },
      {
        name: 'D',
        cents: '8.7980',
      },
      {
        name: 'D#',
        cents: '-0.9770',
      },
      {
        name: 'E',
        cents: '-8.7980',
      },
      {
        name: 'F',
        cents: '2.9330',
      },
      {
        name: 'F#',
        cents: '-4.8880',
      },
      {
        name: 'G',
        cents: '6.8430',
      },
      {
        name: 'G#',
        cents: '-2.9320',
      },
    ],
  },
  {
    name: 'Kirnberger II (1776)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9550',
      },
      {
        name: 'B',
        cents: '-7.8200',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '9.7750',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '-9.7750',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '-5.8650',
      },
      {
        name: 'G',
        cents: '7.8200',
      },
      {
        name: 'G#',
        cents: '-1.9550',
      },
    ],
  },
  {
    name: 'Kirnberger III (1779)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '6.3540',
      },
      {
        name: 'B',
        cents: '-1.4670',
      },
      {
        name: 'C',
        cents: '10.2640',
      },
      {
        name: 'C#',
        cents: '0.4890',
      },
      {
        name: 'D',
        cents: '3.4210',
      },
      {
        name: 'D#',
        cents: '4.3990',
      },
      {
        name: 'E',
        cents: '-3.4220',
      },
      {
        name: 'F',
        cents: '8.3090',
      },
      {
        name: 'F#',
        cents: '0.4880',
      },
      {
        name: 'G',
        cents: '6.8420',
      },
      {
        name: 'G#',
        cents: '2.4440',
      },
    ],
  },
  {
    name: 'Lambert (1774)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.6310',
      },
      {
        name: 'B',
        cents: '-2.7930',
      },
      {
        name: 'C',
        cents: '4.1900',
      },
      {
        name: 'C#',
        cents: '-2.2340',
      },
      {
        name: 'D',
        cents: '1.3970',
      },
      {
        name: 'D#',
        cents: '1.6760',
      },
      {
        name: 'E',
        cents: '-1.3960',
      },
      {
        name: 'F',
        cents: '5.5860',
      },
      {
        name: 'F#',
        cents: '-4.1890',
      },
      {
        name: 'G',
        cents: '2.7930',
      },
      {
        name: 'G#',
        cents: '-0.2790',
      },
    ],
  },
  {
    name: 'Lublin (1540)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '4.1350',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '1.0900',
      },
      {
        name: 'C#',
        cents: '-13.6860',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '2.1800',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '6.0900',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '3.0450',
      },
      {
        name: 'G#',
        cents: '-11.7310',
      },
    ],
  },
  {
    name: 'Malcolm (Alexander, 1721)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '4.9550',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6410',
      },
      {
        name: 'C#',
        cents: '20.5960',
      },
      {
        name: 'D',
        cents: '19.5510',
      },
      {
        name: 'D#',
        cents: '13.1540',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '18.6410',
      },
      {
        name: 'G',
        cents: '17.5960',
      },
      {
        name: 'G#',
        cents: '11.1990',
      },
    ],
  },
  {
    name: 'Marpurg I',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '11.7300',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6400',
      },
      {
        name: 'C#',
        cents: '5.8650',
      },
      {
        name: 'D',
        cents: '19.5500',
      },
      {
        name: 'D#',
        cents: '9.7750',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6850',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '17.5950',
      },
      {
        name: 'G#',
        cents: '7.8200',
      },
    ],
  },
  {
    name: 'Marpurg II',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '9.7750',
      },
      {
        name: 'B',
        cents: '0.0000',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '3.9100',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '7.8200',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '1.9550',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '5.8650',
      },
    ],
  },
  {
    name: 'Marpurg III',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '-13.6850',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-9.7750',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-5.8650',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '-11.7300',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-7.8200',
      },
    ],
  },
  {
    name: 'Marpurg IV',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '-11.7300',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-7.8200',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-11.7300',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '-9.7750',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-5.8650',
      },
    ],
  },
  {
    name: 'Marpurg IX',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9550',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-1.9550',
      },
      {
        name: 'F#',
        cents: '0.0000',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-1.9550',
      },
    ],
  },
  {
    name: 'Marpurg V',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.9100',
      },
      {
        name: 'B',
        cents: '-5.8650',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-1.9550',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '1.9550',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '-3.9100',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '0.0000',
      },
    ],
  },
  {
    name: 'Marpurg VI',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9550',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '-5.8650',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-1.9550',
      },
    ],
  },
  {
    name: 'Marpurg VII',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-1.9550',
      },
      {
        name: 'B',
        cents: '-3.9100',
      },
      {
        name: 'C',
        cents: '1.9550',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-3.9100',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '0.0000',
      },
      {
        name: 'F#',
        cents: '-1.9550',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Marpurg VIII',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9550',
      },
      {
        name: 'B',
        cents: '-1.9550',
      },
      {
        name: 'C',
        cents: '0.0000',
      },
      {
        name: 'C#',
        cents: '1.9550',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-1.9550',
      },
      {
        name: 'F#',
        cents: '0.0000',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-1.9550',
      },
    ],
  },
  {
    name: 'Marpurg X',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9550',
      },
      {
        name: 'B',
        cents: '0.0000',
      },
      {
        name: 'C',
        cents: '1.9550',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '0.0000',
      },
      {
        name: 'F#',
        cents: '1.9550',
      },
      {
        name: 'G',
        cents: '0.0000',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Marpurg XI',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-5.8650',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-3.9100',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '1.9550',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-1.9550',
      },
    ],
  },
  {
    name: 'Marpurg XII',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '0.0000',
      },
      {
        name: 'C',
        cents: '-7.8200',
      },
      {
        name: 'C#',
        cents: '3.9100',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-11.7300',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '1.9550',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '5.8650',
      },
    ],
  },
  {
    name: 'Meantone',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '13.6860',
      },
      {
        name: 'B',
        cents: '-5.8640',
      },
      {
        name: 'C',
        cents: '8.7980',
      },
      {
        name: 'C#',
        cents: '-9.7750',
      },
      {
        name: 'D',
        cents: '2.9330',
      },
      {
        name: 'D#',
        cents: '15.6400',
      },
      {
        name: 'E',
        cents: '-2.9320',
      },
      {
        name: 'F',
        cents: '11.7310',
      },
      {
        name: 'F#',
        cents: '-7.8190',
      },
      {
        name: 'G',
        cents: '5.8650',
      },
      {
        name: 'G#',
        cents: '-10.7520',
      },
    ],
  },
  {
    name: 'Meantone (-1/4) (LargeThird)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '17.1080',
      },
      {
        name: 'B',
        cents: '-6.8430',
      },
      {
        name: 'C',
        cents: '10.2650',
      },
      {
        name: 'C#',
        cents: '-13.6860',
      },
      {
        name: 'D',
        cents: '3.4220',
      },
      {
        name: 'D#',
        cents: '20.5300',
      },
      {
        name: 'E',
        cents: '-3.4210',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '-10.2650',
      },
      {
        name: 'G',
        cents: '6.8430',
      },
      {
        name: 'G#',
        cents: '-17.1080',
      },
    ],
  },
  {
    name: 'Meantone (Homogeneous gradated)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '8.1450',
      },
      {
        name: 'B',
        cents: '-3.2580',
      },
      {
        name: 'C',
        cents: '4.8870',
      },
      {
        name: 'C#',
        cents: '-6.5160',
      },
      {
        name: 'D',
        cents: '1.6290',
      },
      {
        name: 'D#',
        cents: '9.7750',
      },
      {
        name: 'E',
        cents: '-1.6290',
      },
      {
        name: 'F',
        cents: '6.5160',
      },
      {
        name: 'F#',
        cents: '-4.8890',
      },
      {
        name: 'G',
        cents: '3.2580',
      },
      {
        name: 'G#',
        cents: '-8.1450',
      },
    ],
  },
  {
    name: 'Meantone (Homogeneous third)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '20.9500',
      },
      {
        name: 'B',
        cents: '-8.3800',
      },
      {
        name: 'C',
        cents: '12.5700',
      },
      {
        name: 'C#',
        cents: '-16.7600',
      },
      {
        name: 'D',
        cents: '4.1900',
      },
      {
        name: 'D#',
        cents: '25.1400',
      },
      {
        name: 'E',
        cents: '-4.1900',
      },
      {
        name: 'F',
        cents: '16.7600',
      },
      {
        name: 'F#',
        cents: '-12.5700',
      },
      {
        name: 'G',
        cents: '8.3800',
      },
      {
        name: 'G#',
        cents: '-20.9500',
      },
    ],
  },
  {
    name: 'Meantone (Homogeneous)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '11.7300',
      },
      {
        name: 'B',
        cents: '-4.6920',
      },
      {
        name: 'C',
        cents: '7.0380',
      },
      {
        name: 'C#',
        cents: '-9.3840',
      },
      {
        name: 'D',
        cents: '2.3460',
      },
      {
        name: 'D#',
        cents: '14.0760',
      },
      {
        name: 'E',
        cents: '-2.3460',
      },
      {
        name: 'F',
        cents: '9.3840',
      },
      {
        name: 'F#',
        cents: '-7.0380',
      },
      {
        name: 'G',
        cents: '4.6920',
      },
      {
        name: 'G#',
        cents: '-11.7300',
      },
    ],
  },
  {
    name: 'Meantone (Small third)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '26.0700',
      },
      {
        name: 'B',
        cents: '-10.4280',
      },
      {
        name: 'C',
        cents: '15.6420',
      },
      {
        name: 'C#',
        cents: '-20.8560',
      },
      {
        name: 'D',
        cents: '5.2140',
      },
      {
        name: 'D#',
        cents: '31.2840',
      },
      {
        name: 'E',
        cents: '-5.2140',
      },
      {
        name: 'F',
        cents: '20.8560',
      },
      {
        name: 'F#',
        cents: '-15.6420',
      },
      {
        name: 'G',
        cents: '10.4280',
      },
      {
        name: 'G#',
        cents: '-26.0700',
      },
    ],
  },
  {
    name: 'Meantone # (-1/4)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-23.9510',
      },
      {
        name: 'B',
        cents: '-6.8430',
      },
      {
        name: 'C',
        cents: '10.2650',
      },
      {
        name: 'C#',
        cents: '-13.6860',
      },
      {
        name: 'D',
        cents: '3.4220',
      },
      {
        name: 'D#',
        cents: '-20.5290',
      },
      {
        name: 'E',
        cents: '-3.4210',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '-10.2650',
      },
      {
        name: 'G',
        cents: '6.8430',
      },
      {
        name: 'G#',
        cents: '-17.1080',
      },
    ],
  },
  {
    name: 'Meantone b (-1/4)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '17.1080',
      },
      {
        name: 'B',
        cents: '-6.8430',
      },
      {
        name: 'C',
        cents: '10.2650',
      },
      {
        name: 'C#',
        cents: '27.3730',
      },
      {
        name: 'D',
        cents: '3.4220',
      },
      {
        name: 'D#',
        cents: '20.5300',
      },
      {
        name: 'E',
        cents: '-3.4210',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '30.7940',
      },
      {
        name: 'G',
        cents: '6.8430',
      },
      {
        name: 'G#',
        cents: '23.9510',
      },
    ],
  },
  {
    name: 'Meister',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '0.9780',
      },
      {
        name: 'B',
        cents: '-6.8420',
      },
      {
        name: 'C',
        cents: '4.8880',
      },
      {
        name: 'C#',
        cents: '16.6180',
      },
      {
        name: 'D',
        cents: '3.4220',
      },
      {
        name: 'D#',
        cents: '20.5280',
      },
      {
        name: 'E',
        cents: '-3.4210',
      },
      {
        name: 'F',
        cents: '2.9330',
      },
      {
        name: 'F#',
        cents: '14.6630',
      },
      {
        name: 'G',
        cents: '6.8430',
      },
      {
        name: 'G#',
        cents: '18.5730',
      },
    ],
  },
  {
    name: 'Mersenne (Fractions)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '11.7300',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6400',
      },
      {
        name: 'C#',
        cents: '-13.6850',
      },
      {
        name: 'D',
        cents: '19.5500',
      },
      {
        name: 'D#',
        cents: '31.2800',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6850',
      },
      {
        name: 'F#',
        cents: '-15.6400',
      },
      {
        name: 'G',
        cents: '17.5950',
      },
      {
        name: 'G#',
        cents: '29.3250',
      },
    ],
  },
  {
    name: 'Mersenne (Marin, 1636)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.7100',
      },
      {
        name: 'B',
        cents: '3.4210',
      },
      {
        name: 'C',
        cents: '5.1310',
      },
      {
        name: 'C#',
        cents: '1.7100',
      },
      {
        name: 'D',
        cents: '-1.7110',
      },
      {
        name: 'D#',
        cents: '-5.1320',
      },
      {
        name: 'E',
        cents: '-8.5530',
      },
      {
        name: 'F',
        cents: '-6.8430',
      },
      {
        name: 'F#',
        cents: '-5.1320',
      },
      {
        name: 'G',
        cents: '-3.4220',
      },
      {
        name: 'G#',
        cents: '-1.7110',
      },
    ],
  },
  {
    name: 'Naturally harmonious',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '33.2370',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6410',
      },
      {
        name: 'C#',
        cents: '27.3720',
      },
      {
        name: 'D',
        cents: '19.5510',
      },
      {
        name: 'D#',
        cents: '31.2820',
      },
      {
        name: 'E',
        cents: '1.9560',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '5.8640',
      },
      {
        name: 'G',
        cents: '17.5960',
      },
      {
        name: 'G#',
        cents: '29.3270',
      },
    ],
  },
  {
    name: 'Naturally harmonious (Thirds)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '11.7310',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6410',
      },
      {
        name: 'C#',
        cents: '27.3720',
      },
      {
        name: 'D',
        cents: '19.5510',
      },
      {
        name: 'D#',
        cents: '31.2820',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '5.8640',
      },
      {
        name: 'G',
        cents: '17.5960',
      },
      {
        name: 'G#',
        cents: '29.3270',
      },
    ],
  },
  {
    name: 'Neidhardt (1724, Grosse Stadt)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.9100',
      },
      {
        name: 'B',
        cents: '1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '1.9550',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '3.9100',
      },
      {
        name: 'E',
        cents: '0.0000',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '1.9550',
      },
      {
        name: 'G',
        cents: '1.9550',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Neidhardt (1732, Dorf)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.9100',
      },
      {
        name: 'B',
        cents: '-1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '3.9100',
      },
      {
        name: 'D#',
        cents: '1.9550',
      },
      {
        name: 'E',
        cents: '-3.9100',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '-1.9550',
      },
      {
        name: 'G',
        cents: '5.8650',
      },
      {
        name: 'G#',
        cents: '0.0000',
      },
    ],
  },
  {
    name: 'Neidthardt I',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9550',
      },
      {
        name: 'B',
        cents: '-1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '1.9550',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '-1.9550',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Neidthardt II',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.8650',
      },
      {
        name: 'B',
        cents: '1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '1.9550',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '3.9100',
      },
      {
        name: 'E',
        cents: '0.0000',
      },
      {
        name: 'F',
        cents: '5.8650',
      },
      {
        name: 'F#',
        cents: '1.9550',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Neidthardt III',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.9100',
      },
      {
        name: 'B',
        cents: '1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '1.9550',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '3.9100',
      },
      {
        name: 'E',
        cents: '0.0000',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '1.9550',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Organ of Freiberg (Silbermann-Orgel, 1985)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.9100',
      },
      {
        name: 'B',
        cents: '-3.9100',
      },
      {
        name: 'C',
        cents: '3.9100',
      },
      {
        name: 'C#',
        cents: '-5.8650',
      },
      {
        name: 'D',
        cents: '0.0000',
      },
      {
        name: 'D#',
        cents: '1.9550',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '-5.8650',
      },
      {
        name: 'G',
        cents: '1.9550',
      },
      {
        name: 'G#',
        cents: '-5.8650',
      },
    ],
  },
  {
    name: 'Organ of Fribourg (Manderscheidt-Orgel, 1640)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '12.7080',
      },
      {
        name: 'B',
        cents: '-4.8870',
      },
      {
        name: 'C',
        cents: '7.8200',
      },
      {
        name: 'C#',
        cents: '-0.9780',
      },
      {
        name: 'D',
        cents: '2.9330',
      },
      {
        name: 'D#',
        cents: '14.6620',
      },
      {
        name: 'E',
        cents: '-4.8870',
      },
      {
        name: 'F',
        cents: '8.7980',
      },
      {
        name: 'F#',
        cents: '-2.9330',
      },
      {
        name: 'G',
        cents: '5.8650',
      },
      {
        name: 'G#',
        cents: '-10.7520',
      },
    ],
  },
  {
    name: 'Organ of Hamburg (Schnitger-Orgel, 1993)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '7.4290',
      },
      {
        name: 'B',
        cents: '-4.6920',
      },
      {
        name: 'C',
        cents: '7.0380',
      },
      {
        name: 'C#',
        cents: '-5.0830',
      },
      {
        name: 'D',
        cents: '2.3460',
      },
      {
        name: 'D#',
        cents: '3.1280',
      },
      {
        name: 'E',
        cents: '-2.3460',
      },
      {
        name: 'F',
        cents: '9.3840',
      },
      {
        name: 'F#',
        cents: '-7.0380',
      },
      {
        name: 'G',
        cents: '4.6920',
      },
      {
        name: 'G#',
        cents: '-3.1280',
      },
    ],
  },
  {
    name: 'Organ of Maihingen (Baumeister-Orgel, 1737)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-1.9550',
      },
      {
        name: 'B',
        cents: '-9.7750',
      },
      {
        name: 'C',
        cents: '3.9100',
      },
      {
        name: 'C#',
        cents: '-10.7520',
      },
      {
        name: 'D',
        cents: '2.9330',
      },
      {
        name: 'D#',
        cents: '9.7750',
      },
      {
        name: 'E',
        cents: '-8.7970',
      },
      {
        name: 'F',
        cents: '4.8880',
      },
      {
        name: 'F#',
        cents: '-12.7070',
      },
      {
        name: 'G',
        cents: '5.8650',
      },
      {
        name: 'G#',
        cents: '-14.6620',
      },
    ],
  },
  {
    name: 'Organ of Muri (Evangelien-Orgel)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '9.7750',
      },
      {
        name: 'B',
        cents: '-5.8640',
      },
      {
        name: 'C',
        cents: '8.7980',
      },
      {
        name: 'C#',
        cents: '-10.7520',
      },
      {
        name: 'D',
        cents: '3.9110',
      },
      {
        name: 'D#',
        cents: '14.6630',
      },
      {
        name: 'E',
        cents: '-2.9320',
      },
      {
        name: 'F',
        cents: '10.7530',
      },
      {
        name: 'F#',
        cents: '-8.7970',
      },
      {
        name: 'G',
        cents: '7.8210',
      },
      {
        name: 'G#',
        cents: '-11.7300',
      },
    ],
  },
  {
    name: 'Organ of Niederbobritzsch (Goethel-Orgel)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.4660',
      },
      {
        name: 'B',
        cents: '-5.8650',
      },
      {
        name: 'C',
        cents: '0.0000',
      },
      {
        name: 'C#',
        cents: '-1.9550',
      },
      {
        name: 'D',
        cents: '2.4440',
      },
      {
        name: 'D#',
        cents: '-1.9550',
      },
      {
        name: 'E',
        cents: '-0.9780',
      },
      {
        name: 'F',
        cents: '3.4210',
      },
      {
        name: 'F#',
        cents: '0.4890',
      },
      {
        name: 'G',
        cents: '-0.9770',
      },
      {
        name: 'G#',
        cents: '-1.9550',
      },
    ],
  },
  {
    name: 'Organ of Weingarten (Gabler-Orgel, 1750)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '10.6650',
      },
      {
        name: 'B',
        cents: '-4.2660',
      },
      {
        name: 'C',
        cents: '6.3990',
      },
      {
        name: 'C#',
        cents: '-8.5320',
      },
      {
        name: 'D',
        cents: '2.1330',
      },
      {
        name: 'D#',
        cents: '12.7980',
      },
      {
        name: 'E',
        cents: '-2.1330',
      },
      {
        name: 'F',
        cents: '8.5320',
      },
      {
        name: 'F#',
        cents: '-6.3990',
      },
      {
        name: 'G',
        cents: '4.2660',
      },
      {
        name: 'G#',
        cents: '-10.6650',
      },
    ],
  },
  {
    name: 'Organ of Weingarten (Gabler-Orgel, 1983)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '6.3540',
      },
      {
        name: 'B',
        cents: '-3.9090',
      },
      {
        name: 'C',
        cents: '4.8880',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '0.9780',
      },
      {
        name: 'D#',
        cents: '6.3540',
      },
      {
        name: 'E',
        cents: '-0.9770',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '-3.9100',
      },
      {
        name: 'G',
        cents: '2.9330',
      },
      {
        name: 'G#',
        cents: '2.9330',
      },
    ],
  },
  {
    name: 'Pythagorean',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '7.8200',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-11.7300',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '9.7750',
      },
    ],
  },
  {
    name: 'Pythagorean (Fifth chain Ab - C#)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '7.8200',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-11.7300',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-13.6850',
      },
    ],
  },
  {
    name: 'Pythagorean (Fifth chain Bb - D#)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '7.8200',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '11.7300',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '9.7750',
      },
    ],
  },
  {
    name: 'Pythagorean (Fifth chain Cb - E)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '-19.5500',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-15.6400',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-11.7300',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '-17.5950',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-13.6850',
      },
    ],
  },
  {
    name: 'Pythagorean (Fifth chain Db - F#)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-15.6400',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-11.7300',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-13.6850',
      },
    ],
  },
  {
    name: 'Pythagorean (Fifth chain Ebb - G)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '13.6850',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '17.5950',
      },
      {
        name: 'C#',
        cents: '7.8200',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '11.7300',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '15.6400',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '19.5500',
      },
      {
        name: 'G#',
        cents: '9.7750',
      },
    ],
  },
  {
    name: 'Pythagorean (Fifth chain F - A#)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '13.6850',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '7.8200',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '11.7300',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '9.7750',
      },
    ],
  },
  {
    name: 'Pythagorean (Fifth chain Fb - A)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '-19.5500',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-15.6400',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-11.7300',
      },
      {
        name: 'E',
        cents: '-21.5050',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '-17.5950',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-13.6850',
      },
    ],
  },
  {
    name: 'Pythagorean (Fifth chain Gb - B)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-15.6400',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-11.7300',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '-17.5950',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-13.6850',
      },
    ],
  },
  {
    name: 'Pythagorean (Perfect)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '11.7300',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6400',
      },
      {
        name: 'C#',
        cents: '7.8200',
      },
      {
        name: 'D',
        cents: '19.5500',
      },
      {
        name: 'D#',
        cents: '11.7300',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6850',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '17.5950',
      },
      {
        name: 'G#',
        cents: '9.7750',
      },
    ],
  },
  {
    name: 'Pythagorei comma (3-Split)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.8650',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '9.7750',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '3.9100',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '7.8200',
      },
      {
        name: 'F#',
        cents: '-1.9550',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Pythagorei comma (4-Split)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9550',
      },
      {
        name: 'B',
        cents: '-1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '1.9550',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '0.0000',
      },
      {
        name: 'G',
        cents: '1.9550',
      },
      {
        name: 'G#',
        cents: '3.9100',
      },
    ],
  },
  {
    name: 'Pythagorei comma (5-Split)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '4.3010',
      },
      {
        name: 'B',
        cents: '-5.4740',
      },
      {
        name: 'C',
        cents: '8.2110',
      },
      {
        name: 'C#',
        cents: '-1.5640',
      },
      {
        name: 'D',
        cents: '2.7370',
      },
      {
        name: 'D#',
        cents: '2.3460',
      },
      {
        name: 'E',
        cents: '-2.7370',
      },
      {
        name: 'F',
        cents: '6.2560',
      },
      {
        name: 'F#',
        cents: '-3.5190',
      },
      {
        name: 'G',
        cents: '5.4740',
      },
      {
        name: 'G#',
        cents: '0.3910',
      },
    ],
  },
  {
    name: 'Pythagorei comma (6+12-Split)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '7.8200',
      },
      {
        name: 'B',
        cents: '-3.9100',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '7.8200',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '7.8200',
      },
      {
        name: 'F#',
        cents: '-3.9100',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '-3.9100',
      },
    ],
  },
  {
    name: 'Pythagorei comma (6-Split)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9550',
      },
      {
        name: 'B',
        cents: '-3.9100',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '-5.8650',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '-1.9550',
      },
    ],
  },
  {
    name: 'Rameau I',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '7.8200',
      },
      {
        name: 'B',
        cents: '-7.8200',
      },
      {
        name: 'C',
        cents: '11.7300',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '3.9100',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '-3.9100',
      },
      {
        name: 'F',
        cents: '15.6400',
      },
      {
        name: 'F#',
        cents: '-5.8650',
      },
      {
        name: 'G',
        cents: '7.8200',
      },
      {
        name: 'G#',
        cents: '-1.9550',
      },
    ],
  },
  {
    name: 'Rameau II (-1/4)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '4.5620',
      },
      {
        name: 'B',
        cents: '-6.8430',
      },
      {
        name: 'C',
        cents: '10.2650',
      },
      {
        name: 'C#',
        cents: '-2.9330',
      },
      {
        name: 'D',
        cents: '3.4220',
      },
      {
        name: 'D#',
        cents: '-4.5610',
      },
      {
        name: 'E',
        cents: '-3.4210',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '-4.8880',
      },
      {
        name: 'G',
        cents: '6.8430',
      },
      {
        name: 'G#',
        cents: '-0.9780',
      },
    ],
  },
  {
    name: 'Ramis de Pareia (1482)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '11.7310',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6410',
      },
      {
        name: 'C#',
        cents: '7.8200',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '9.7760',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '5.8650',
      },
      {
        name: 'G',
        cents: '17.5960',
      },
      {
        name: 'G#',
        cents: '7.8210',
      },
    ],
  },
  {
    name: 'Reinhard (Andreas, 1604)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-1.0460',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6410',
      },
      {
        name: 'C#',
        cents: '14.5960',
      },
      {
        name: 'D',
        cents: '19.5510',
      },
      {
        name: 'D#',
        cents: '8.3520',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '12.6410',
      },
      {
        name: 'G',
        cents: '17.5960',
      },
      {
        name: 'G#',
        cents: '6.3970',
      },
    ],
  },
  {
    name: 'Rossi (-1/5)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '11.7310',
      },
      {
        name: 'B',
        cents: '-4.6930',
      },
      {
        name: 'C',
        cents: '7.0390',
      },
      {
        name: 'C#',
        cents: '-9.3850',
      },
      {
        name: 'D',
        cents: '2.3460',
      },
      {
        name: 'D#',
        cents: '14.0780',
      },
      {
        name: 'E',
        cents: '-2.3460',
      },
      {
        name: 'F',
        cents: '9.3850',
      },
      {
        name: 'F#',
        cents: '-7.0390',
      },
      {
        name: 'G',
        cents: '4.6930',
      },
      {
        name: 'G#',
        cents: '-11.7310',
      },
    ],
  },
  {
    name: 'Rossi (-2/9)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '14.1210',
      },
      {
        name: 'B',
        cents: '-5.6480',
      },
      {
        name: 'C',
        cents: '8.4730',
      },
      {
        name: 'C#',
        cents: '-11.2960',
      },
      {
        name: 'D',
        cents: '2.8240',
      },
      {
        name: 'D#',
        cents: '16.9450',
      },
      {
        name: 'E',
        cents: '-2.8240',
      },
      {
        name: 'F',
        cents: '11.2970',
      },
      {
        name: 'F#',
        cents: '-8.4720',
      },
      {
        name: 'G',
        cents: '5.6480',
      },
      {
        name: 'G#',
        cents: '-14.1210',
      },
    ],
  },
  {
    name: 'Salinas (-1/3)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '26.0690',
      },
      {
        name: 'B',
        cents: '-10.4270',
      },
      {
        name: 'C',
        cents: '15.6420',
      },
      {
        name: 'C#',
        cents: '-20.8540',
      },
      {
        name: 'D',
        cents: '5.2140',
      },
      {
        name: 'D#',
        cents: '31.2830',
      },
      {
        name: 'E',
        cents: '-5.2130',
      },
      {
        name: 'F',
        cents: '20.8560',
      },
      {
        name: 'F#',
        cents: '-15.6410',
      },
      {
        name: 'G',
        cents: '10.4280',
      },
      {
        name: 'G#',
        cents: '-26.0680',
      },
    ],
  },
  {
    name: 'Schlick (Barbour)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '7.8200',
      },
      {
        name: 'B',
        cents: '-3.9100',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '7.8200',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '7.8200',
      },
      {
        name: 'F#',
        cents: '-3.9100',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Schlick (Dupont)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '17.1100',
      },
      {
        name: 'B',
        cents: '-6.8440',
      },
      {
        name: 'C',
        cents: '10.2660',
      },
      {
        name: 'C#',
        cents: '-13.6880',
      },
      {
        name: 'D',
        cents: '3.4220',
      },
      {
        name: 'D#',
        cents: '20.5320',
      },
      {
        name: 'E',
        cents: '-3.4220',
      },
      {
        name: 'F',
        cents: '13.6880',
      },
      {
        name: 'F#',
        cents: '-10.2660',
      },
      {
        name: 'G',
        cents: '6.8440',
      },
      {
        name: 'G#',
        cents: '3.4220',
      },
    ],
  },
  {
    name: 'Schlick (Lange)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '10.3850',
      },
      {
        name: 'B',
        cents: '-4.1540',
      },
      {
        name: 'C',
        cents: '6.2310',
      },
      {
        name: 'C#',
        cents: '-8.3080',
      },
      {
        name: 'D',
        cents: '2.0770',
      },
      {
        name: 'D#',
        cents: '12.4620',
      },
      {
        name: 'E',
        cents: '-2.0770',
      },
      {
        name: 'F',
        cents: '8.3080',
      },
      {
        name: 'F#',
        cents: '-6.2310',
      },
      {
        name: 'G',
        cents: '4.1540',
      },
      {
        name: 'G#',
        cents: '6.4750',
      },
    ],
  },
  {
    name: 'Schlick (Ratte)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '7.8200',
      },
      {
        name: 'B',
        cents: '-3.9100',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '-5.8650',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '9.7750',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '7.8200',
      },
      {
        name: 'F#',
        cents: '-3.9100',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '5.8650',
      },
    ],
  },
  {
    name: 'Schlick (Schugk)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '13.6850',
      },
      {
        name: 'B',
        cents: '-5.4740',
      },
      {
        name: 'C',
        cents: '8.2110',
      },
      {
        name: 'C#',
        cents: '-10.9480',
      },
      {
        name: 'D',
        cents: '2.7370',
      },
      {
        name: 'D#',
        cents: '16.4220',
      },
      {
        name: 'E',
        cents: '-2.7370',
      },
      {
        name: 'F',
        cents: '10.9480',
      },
      {
        name: 'F#',
        cents: '-8.2110',
      },
      {
        name: 'G',
        cents: '5.4740',
      },
      {
        name: 'G#',
        cents: '19.1590',
      },
    ],
  },
  {
    name: 'Schlick (Tessmer)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '9.7750',
      },
      {
        name: 'B',
        cents: '-4.8880',
      },
      {
        name: 'C',
        cents: '7.3310',
      },
      {
        name: 'C#',
        cents: '-4.8880',
      },
      {
        name: 'D',
        cents: '2.4440',
      },
      {
        name: 'D#',
        cents: '9.7750',
      },
      {
        name: 'E',
        cents: '-2.4440',
      },
      {
        name: 'F',
        cents: '9.7750',
      },
      {
        name: 'F#',
        cents: '-4.8880',
      },
      {
        name: 'G',
        cents: '4.8870',
      },
      {
        name: 'G#',
        cents: '5.8650',
      },
    ],
  },
  {
    name: 'Schlick (Vogel)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '8.9930',
      },
      {
        name: 'B',
        cents: '-5.4740',
      },
      {
        name: 'C',
        cents: '8.2110',
      },
      {
        name: 'C#',
        cents: '-6.2560',
      },
      {
        name: 'D',
        cents: '2.7370',
      },
      {
        name: 'D#',
        cents: '2.3460',
      },
      {
        name: 'E',
        cents: '-2.7370',
      },
      {
        name: 'F',
        cents: '10.9480',
      },
      {
        name: 'F#',
        cents: '-8.2110',
      },
      {
        name: 'G',
        cents: '5.4740',
      },
      {
        name: 'G#',
        cents: '-4.3010',
      },
    ],
  },
  {
    name: 'Schneegass I (1590)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '17.2330',
      },
      {
        name: 'B',
        cents: '-6.8930',
      },
      {
        name: 'C',
        cents: '10.3400',
      },
      {
        name: 'C#',
        cents: '-13.7860',
      },
      {
        name: 'D',
        cents: '3.4470',
      },
      {
        name: 'D#',
        cents: '20.6800',
      },
      {
        name: 'E',
        cents: '-3.4460',
      },
      {
        name: 'F',
        cents: '13.7870',
      },
      {
        name: 'F#',
        cents: '-10.3400',
      },
      {
        name: 'G',
        cents: '6.8930',
      },
      {
        name: 'G#',
        cents: '-17.2330',
      },
    ],
  },
  {
    name: 'Schneegass II (1590)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '17.2350',
      },
      {
        name: 'B',
        cents: '-3.7610',
      },
      {
        name: 'C',
        cents: '10.3410',
      },
      {
        name: 'C#',
        cents: '-10.6550',
      },
      {
        name: 'D',
        cents: '3.4470',
      },
      {
        name: 'D#',
        cents: '20.6820',
      },
      {
        name: 'E',
        cents: '-0.3140',
      },
      {
        name: 'F',
        cents: '13.7880',
      },
      {
        name: 'F#',
        cents: '-7.2080',
      },
      {
        name: 'G',
        cents: '6.8940',
      },
      {
        name: 'G#',
        cents: '-14.1020',
      },
    ],
  },
  {
    name: 'Schneegass III (1590)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '15.6560',
      },
      {
        name: 'B',
        cents: '-4.3190',
      },
      {
        name: 'C',
        cents: '10.1980',
      },
      {
        name: 'C#',
        cents: '-9.0200',
      },
      {
        name: 'D',
        cents: '4.2700',
      },
      {
        name: 'D#',
        cents: '18.3060',
      },
      {
        name: 'E',
        cents: '-1.4490',
      },
      {
        name: 'F',
        cents: '11.1050',
      },
      {
        name: 'F#',
        cents: '-8.5760',
      },
      {
        name: 'G',
        cents: '6.1580',
      },
      {
        name: 'G#',
        cents: '-14.4630',
      },
    ],
  },
  {
    name: 'Silbermann (-1/6)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '8.1470',
      },
      {
        name: 'B',
        cents: '-3.2580',
      },
      {
        name: 'C',
        cents: '4.8890',
      },
      {
        name: 'C#',
        cents: '-6.5170',
      },
      {
        name: 'D',
        cents: '1.6300',
      },
      {
        name: 'D#',
        cents: '9.7770',
      },
      {
        name: 'E',
        cents: '-1.6290',
      },
      {
        name: 'F',
        cents: '6.5180',
      },
      {
        name: 'F#',
        cents: '-4.8880',
      },
      {
        name: 'G',
        cents: '3.2590',
      },
      {
        name: 'G#',
        cents: '-8.1460',
      },
    ],
  },
  {
    name: 'Silbermann (1/6)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '9.7750',
      },
      {
        name: 'B',
        cents: '-3.9100',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '-7.8200',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '11.7300',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '7.8200',
      },
      {
        name: 'F#',
        cents: '-5.8650',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '-9.7750',
      },
    ],
  },
  {
    name: 'Sorge',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.9100',
      },
      {
        name: 'B',
        cents: '1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '1.9550',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '3.9100',
      },
      {
        name: 'E',
        cents: '0.0000',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '1.9550',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '3.9100',
      },
    ],
  },
  {
    name: 'Stanhope (1801)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.8650',
      },
      {
        name: 'B',
        cents: '-3.9100',
      },
      {
        name: 'C',
        cents: '9.7750',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '5.8650',
      },
      {
        name: 'D#',
        cents: '3.9100',
      },
      {
        name: 'E',
        cents: '-5.8650',
      },
      {
        name: 'F',
        cents: '7.8200',
      },
      {
        name: 'F#',
        cents: '-1.9550',
      },
      {
        name: 'G',
        cents: '11.7300',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Syntonic comma (2-Split)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '22.4840',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '15.6410',
      },
      {
        name: 'C#',
        cents: '-13.6860',
      },
      {
        name: 'D',
        cents: '8.7980',
      },
      {
        name: 'D#',
        cents: '31.2820',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '13.6860',
      },
      {
        name: 'F#',
        cents: '-4.8880',
      },
      {
        name: 'G',
        cents: '17.5960',
      },
      {
        name: 'G#',
        cents: '-11.7310',
      },
    ],
  },
  {
    name: 'Syntonic comma (4-Split)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '6.3540',
      },
      {
        name: 'B',
        cents: '-6.8440',
      },
      {
        name: 'C',
        cents: '10.2640',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '3.4210',
      },
      {
        name: 'D#',
        cents: '4.3990',
      },
      {
        name: 'E',
        cents: '-3.4220',
      },
      {
        name: 'F',
        cents: '8.3090',
      },
      {
        name: 'F#',
        cents: '-10.2650',
      },
      {
        name: 'G',
        cents: '6.8420',
      },
      {
        name: 'G#',
        cents: '2.4440',
      },
    ],
  },
  {
    name: 'Syntonic comma (5-Split)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '4.6920',
      },
      {
        name: 'B',
        cents: '-1.1730',
      },
      {
        name: 'C',
        cents: '7.0380',
      },
      {
        name: 'C#',
        cents: '1.1730',
      },
      {
        name: 'D',
        cents: '2.3460',
      },
      {
        name: 'D#',
        cents: '3.5190',
      },
      {
        name: 'E',
        cents: '-2.3460',
      },
      {
        name: 'F',
        cents: '5.8650',
      },
      {
        name: 'F#',
        cents: '0.0000',
      },
      {
        name: 'G',
        cents: '4.6920',
      },
      {
        name: 'G#',
        cents: '2.3460',
      },
    ],
  },
  {
    name: 'Trost (Johann Caspar, 1677)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.4210',
      },
      {
        name: 'B',
        cents: '-13.6850',
      },
      {
        name: 'C',
        cents: '-3.4210',
      },
      {
        name: 'C#',
        cents: '-6.8420',
      },
      {
        name: 'D',
        cents: '3.4220',
      },
      {
        name: 'D#',
        cents: '-6.8420',
      },
      {
        name: 'E',
        cents: '-3.4210',
      },
      {
        name: 'F',
        cents: '0.0000',
      },
      {
        name: 'F#',
        cents: '-3.4210',
      },
      {
        name: 'G',
        cents: '-6.8420',
      },
      {
        name: 'G#',
        cents: '-10.2630',
      },
    ],
  },
  {
    name: 'Valotti (1754)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.8650',
      },
      {
        name: 'B',
        cents: '-3.9100',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '3.9100',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '7.8200',
      },
      {
        name: 'F#',
        cents: '-1.9550',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Van Zwolle',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-9.7750',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '-5.8650',
      },
      {
        name: 'C#',
        cents: '-15.6400',
      },
      {
        name: 'D',
        cents: '-1.9550',
      },
      {
        name: 'D#',
        cents: '-11.7300',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '-7.8200',
      },
      {
        name: 'F#',
        cents: '-17.5950',
      },
      {
        name: 'G',
        cents: '-3.9100',
      },
      {
        name: 'G#',
        cents: '-13.6850',
      },
    ],
  },
  {
    name: 'Veroli (Ordinaire)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '4.4010',
      },
      {
        name: 'B',
        cents: '-6.8440',
      },
      {
        name: 'C',
        cents: '10.2660',
      },
      {
        name: 'C#',
        cents: '-8.7990',
      },
      {
        name: 'D',
        cents: '3.4220',
      },
      {
        name: 'D#',
        cents: '-1.4640',
      },
      {
        name: 'E',
        cents: '-3.4220',
      },
      {
        name: 'F',
        cents: '8.3110',
      },
      {
        name: 'F#',
        cents: '-8.7990',
      },
      {
        name: 'G',
        cents: '6.8440',
      },
      {
        name: 'G#',
        cents: '-6.8440',
      },
    ],
  },
  {
    name: 'Werckmeister I',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '7.8200',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '11.7300',
      },
      {
        name: 'C#',
        cents: '1.9550',
      },
      {
        name: 'D',
        cents: '3.9100',
      },
      {
        name: 'D#',
        cents: '5.8650',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '9.7750',
      },
      {
        name: 'F#',
        cents: '0.0000',
      },
      {
        name: 'G',
        cents: '7.8200',
      },
      {
        name: 'G#',
        cents: '3.9100',
      },
    ],
  },
  {
    name: 'Werckmeister II',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '13.6850',
      },
      {
        name: 'B',
        cents: '-3.9100',
      },
      {
        name: 'C',
        cents: '9.7750',
      },
      {
        name: 'C#',
        cents: '-7.8200',
      },
      {
        name: 'D',
        cents: '5.8650',
      },
      {
        name: 'D#',
        cents: '3.9100',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '7.8200',
      },
      {
        name: 'F#',
        cents: '-1.9550',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '-5.8650',
      },
    ],
  },
  {
    name: 'Werckmeister IV',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '6.9810',
      },
      {
        name: 'B',
        cents: '3.9100',
      },
      {
        name: 'C',
        cents: '7.5390',
      },
      {
        name: 'C#',
        cents: '-2.2360',
      },
      {
        name: 'D',
        cents: '-5.3070',
      },
      {
        name: 'D#',
        cents: '5.0260',
      },
      {
        name: 'E',
        cents: '1.9550',
      },
      {
        name: 'F',
        cents: '5.5840',
      },
      {
        name: 'F#',
        cents: '2.5130',
      },
      {
        name: 'G',
        cents: '6.0900',
      },
      {
        name: 'G#',
        cents: '-0.2810',
      },
    ],
  },
  {
    name: 'Werckmeister ?',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9550',
      },
      {
        name: 'B',
        cents: '-1.9550',
      },
      {
        name: 'C',
        cents: '0.0000',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '3.9100',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '-3.9100',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '0.0000',
      },
      {
        name: 'G',
        cents: '1.9550',
      },
      {
        name: 'G#',
        cents: '-7.8200',
      },
    ],
  },
  {
    name: 'Wiegleb',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '3.9100',
      },
      {
        name: 'B',
        cents: '-1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '-1.9550',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '1.9550',
      },
      {
        name: 'E',
        cents: '0.0000',
      },
      {
        name: 'F',
        cents: '5.8650',
      },
      {
        name: 'F#',
        cents: '-3.9100',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '0.0000',
      },
    ],
  },
  {
    name: 'Wiegleb (1790)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.8650',
      },
      {
        name: 'B',
        cents: '-0.9770',
      },
      {
        name: 'C',
        cents: '8.7980',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '2.9330',
      },
      {
        name: 'D#',
        cents: '3.9110',
      },
      {
        name: 'E',
        cents: '0.0000',
      },
      {
        name: 'F',
        cents: '7.8200',
      },
      {
        name: 'F#',
        cents: '-1.9550',
      },
      {
        name: 'G',
        cents: '5.8650',
      },
      {
        name: 'G#',
        cents: '1.9560',
      },
    ],
  },
  {
    name: 'Young I',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.8650',
      },
      {
        name: 'B',
        cents: '-1.9550',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '0.0000',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '3.9100',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '5.8650',
      },
      {
        name: 'F#',
        cents: '-1.9550',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '1.9550',
      },
    ],
  },
  {
    name: 'Young II',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '1.9550',
      },
      {
        name: 'B',
        cents: '-3.9100',
      },
      {
        name: 'C',
        cents: '5.8650',
      },
      {
        name: 'C#',
        cents: '-3.9100',
      },
      {
        name: 'D',
        cents: '1.9550',
      },
      {
        name: 'D#',
        cents: '0.0000',
      },
      {
        name: 'E',
        cents: '-1.9550',
      },
      {
        name: 'F',
        cents: '3.9100',
      },
      {
        name: 'F#',
        cents: '-5.8650',
      },
      {
        name: 'G',
        cents: '3.9100',
      },
      {
        name: 'G#',
        cents: '-1.9550',
      },
    ],
  },
  {
    name: 'Bach (Jobin)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '5.2000',
      },
      {
        name: 'B',
        cents: '-6.8000',
      },
      {
        name: 'C',
        cents: '10.3000',
      },
      {
        name: 'C#',
        cents: '-2.9000',
      },
      {
        name: 'D',
        cents: '3.4000',
      },
      {
        name: 'D#',
        cents: '2.1000',
      },
      {
        name: 'E',
        cents: '-3.4000',
      },
      {
        name: 'F',
        cents: '8.3000',
      },
      {
        name: 'F#',
        cents: '-4.9000',
      },
      {
        name: 'G',
        cents: '6.8000',
      },
      {
        name: 'G#',
        cents: '-1.0000',
      },
    ],
  },
  {
    name: 'Zarlino (-2/7)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '20.9480',
      },
      {
        name: 'B',
        cents: '-8.3800',
      },
      {
        name: 'C',
        cents: '12.5690',
      },
      {
        name: 'C#',
        cents: '-16.7590',
      },
      {
        name: 'D',
        cents: '4.1900',
      },
      {
        name: 'D#',
        cents: '25.1380',
      },
      {
        name: 'E',
        cents: '-4.1900',
      },
      {
        name: 'F',
        cents: '16.7580',
      },
      {
        name: 'F#',
        cents: '-12.5690',
      },
      {
        name: 'G',
        cents: '8.3790',
      },
      {
        name: 'G#',
        cents: '-20.9490',
      },
    ],
  },
  {
    name: 'True Temperament (Chromatic)',
    noteElement: [
      {
        name: 'A',
        cents: '0.0000',
      },
      {
        name: 'A#',
        cents: '-4.0000',
      },
      {
        name: 'B',
        cents: '-1.0000',
      },
      {
        name: 'C',
        cents: '2.0000',
      },
      {
        name: 'C#',
        cents: '-4.0000',
      },
      {
        name: 'D',
        cents: '2.0000',
      },
      {
        name: 'D#',
        cents: '-4.0000',
      },
      {
        name: 'E',
        cents: '-2.0000',
      },
      {
        name: 'F',
        cents: '0.0000',
      },
      {
        name: 'F#',
        cents: '-4.0000',
      },
      {
        name: 'G',
        cents: '4.0000',
      },
      {
        name: 'G#',
        cents: '-4.0000',
      },
    ],
  },
];
