import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Dispatch} from '../../redux/redux';
import {RootState} from '@abstractions/redux_inst';

import {
  BottomNavigation,
  BottomNavigationAction,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import {MyChooser} from './my_chooser';
import {
  Cancel as CancelIcon,
  DoneOutline as DoneOutlineIcon,
} from '@material-ui/icons';
import {dbg} from '../../debug/debug';
import {TuningElement} from '../../instruments/instrument_defs';

import {MyChooser_renderInstrument} from './choose_instrument';
import useSnackbarHelp from './use_snackbar_help';
import '../../../../App.css';

export type ChooseTemperamentProps = {
  open: boolean;
  onClose: () => void;
};

export function ChooseTemperamentScreen(props: ChooseTemperamentProps) {
  const [temperamentStrings, setTemperamentStrings] = useState<string[]>([]);
  const [chosenTemperament, setChosenTemperament] = useState<
    null | undefined | TuningElement
  >(null);

  const {currentTemperamentName, allTemperamentsMap} = useSelector(
    (state: RootState) => {
      return state.system;
    },
  );

  useEffect(() => {
    const t = allTemperamentsMap.get(currentTemperamentName);
    setChosenTemperament(t);
  }, [currentTemperamentName, allTemperamentsMap]);

  useEffect(() => {
    const t: string[] = [...allTemperamentsMap.keys()];
    setTemperamentStrings(t);
  }, [allTemperamentsMap]);

  const dispatch = useDispatch();
  const help = useSnackbarHelp();

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id="simple-dialog-title">Choose Temperament</DialogTitle>
      <div className="Settings" style={{flexDirection: 'column', flex: 1}}>
        <div style={{flexDirection: 'column', padding: 20, width: '100%'}}>
          <MyChooser
            options={temperamentStrings}
            label="Choose temperament"
            onChange={(_element: null | string) => {
              dbg.log('Chose temperament ' + _element);
              if (_element) {
                const _chosenIndex = allTemperamentsMap.get(_element);
                const chosenIndex = _chosenIndex ? _chosenIndex : null;
                setChosenTemperament(chosenIndex);
              }
            }}
            doRender={MyChooser_renderInstrument}
          />
        </div>
      </div>
      <div className="Small" style={{flexDirection: 'row', flex: 0.2}}></div>
      <BottomNavigation
        style={{width: '100%', paddingBottom: 10, paddingTop: 10}}
        onChange={(event, newValue) => {
          dbg.log('onChange: with event ' + event, newValue);
          switch (newValue) {
            case 0:
              // Accept
              const _name: undefined | string = chosenTemperament?.name
                ? chosenTemperament.name
                : '';
              const name = _name ? _name : '';
              dispatch(Dispatch.setCurrentTemperamentByName(name));
              props.onClose();
              break;
            case 1:
              // Cancel
              props.onClose();
              break;
            default:
              break;
          }
        }}
        showLabels
        // className={classes.root}
      >
        <BottomNavigationAction
          label="Apply"
          icon={<DoneOutlineIcon />}
          {...help.mouseAndFocusEvents(
            'Apply temperament "' +
              (chosenTemperament ? chosenTemperament.name : '') +
              '"',
          )}
        />
        <BottomNavigationAction
          label="Cancel"
          icon={<CancelIcon />}
          {...help.mouseAndFocusEvents('Exit without choosing temperament')}
        />
      </BottomNavigation>
      <div className="Small" style={{flexDirection: 'row', flex: 0.1}}></div>
      {help.Snackbar}
    </Dialog>
  );
}
